<template>
	<div>
		<div class="task-check" v-loading="maxBoxLoading">
			<div class="person-header flex-sb">
				<p><strong>工作成果验收</strong></p>
				<span class="f-14 b" @click="goTaskInfo">返回</span>
			</div>
			<div class="mb-40"></div>
			<div class="task-checktabel flex">
				<div class="task-checktabel-left flex-sb flex-wp" v-show="workItemList.length > 1">
					<div class="task-checktabel-left-button flex-sb" v-if="canCheck">
						<el-tooltip v-show=" !isCheckAll" class="item" effect="dark" content="将所有已提交工作的自由职业全部验收" placement="top">
							<el-button type="text" class="b" @click="allCheck()">一键验收</el-button>
						</el-tooltip>
						<el-tooltip v-show=" isCheckAll" class="item" effect="dark" content="退出一键验收状态" placement="top">
							<el-button type="text" style="color: red;" @click="cancelAllCheck()">
								<i class="iconfont icon-reload24X24 f-12"></i>
								退出一键操作
							</el-button>
						</el-tooltip>
					</div>
					<div class="mb-20"></div>
					<div class="task-checktabel-left-tabel">
						<el-table
							ref="multipleTable"
							@row-click="clickData"
							:data="workItemList"
							style="width: 200px; font-size: 12px"
							border
							@selection-change="handleSelectionChange"
							key="one"
							:header-cell-style="{ 'text-align': 'center', height: '32px', color: '#fff', backgroundColor: '#575757' }"
							:cell-style="{ 'text-align': 'center', height: '32px', color: '#474747', 'background-color': '#f4f4f4' }"
						>
							<el-table-column type="selection" width="30" :selectable="selectableFunc"></el-table-column>
							<el-table-column label="姓名" prop="freelancer.name" key="name"></el-table-column>
							<el-table-column label="状态" width="90" key="status_name">
								<template slot-scope="{ row }">
									<b v-if="row.status == 4" style="color: red;">{{ row.status_name }}</b>
									<b v-else-if="row.status == 5" style="color: red;">{{ row.status_name }}</b>
									<b v-else-if="row.status == 6" style="color: #55A532;">{{ row.status_name }}</b>
									<b v-else style="color: #55A532;">{{ row.status_name }}</b>
								</template>
							</el-table-column>
						</el-table>

						<!--当前页面小于总页数时，显示加载分页-->
						<div class="task-checktabel-left-tabel-add" v-show="workItemPage.page < workItemPage.page_num"><el-button type="text">加载更多</el-button></div>
					</div>
				</div>

				<div class="task-checktabel-right" v-loading="rightBoxLoaing">
					<h2 class="f-18" v-show="isCheckAll">一键批量验收</h2>
					<h2 class="f-18" v-show="!isCheckAll && selectedWorkItems.length > 0">
						雇员：
						<span v-for="(item, index) in selectedWorkItems">{{ item.freelancer.name }}</span>
						<span v-if="selectedWorkItems.length > 1" class="ml-20 f-14 h-57" style="padding:0 5px">等{{ selectedWorkItems.length }}人</span>
					</h2>

					<!-- 未提交工作成果 暂时先隐藏  -->
					<div class="task-checktabel-right-no" v-show="!isCheckAll && selectedWorkItems.length == 0">
						<svg class="icon wh-64" aria-hidden="true"><use xlink:href="#icon-a-noinformation"></use></svg>
						<div class="mb-40"></div>
						<span class="f-18 h-57">请在左侧选择一个自由职业者进行验收</span>
					</div>

					<!-- 未提交工作成果 暂时先隐藏  -->
					<div class="task-checktabel-right-no" v-show="isShowEmpty">
						<svg class="icon wh-64" aria-hidden="true"><use xlink:href="#icon-a-noinformation"></use></svg>
						<div class="mb-40"></div>
						<span class="f-18 h-57">本雇员暂未提交工作成果</span>
					</div>

					<!-- 多个人 时的状态 -->
					<div class="task-checktabel-right-one" v-show="selectedWorkItems.length > 1 || isCheckAll">
						<div class="checkMoney-tabel">
							<div class="checkMoney-tabel-header">
								<strong class="f-18">验收</strong>
								<div class="mb-20"></div>
							</div>
							<div class="mb-20"></div>
							<div class="checkMoney-tabel-input"><span class="f-1 h-57">验收金额：依照每个人的合同金额进行验收</span></div>
							<div class="checkMoney-tabel-remark" v-loading="checkLoading">
								<strong class="f-1 h-57">验收备注：</strong>
								<div class="mb-10"></div>
								<el-input type="textarea" maxlength="300" show-word-limit placeholder="请填写验收备注" v-model="batchCheckMemo" class="input-60"></el-input>
							</div>
							<div class="mb-20"></div>
							<div class="checkMoney-tabel-button">
								<div class="checkMoney-tabel-button-border flex-sb">
									<el-button class="primary" @click="batchCheck('y')">通过验收</el-button>
									<el-button class="refuse" @click="batchCheck('n')">拒绝通过</el-button>
								</div>
							</div>
							<div class="mb-40"></div>
						</div>
					</div>

					<!-- 单个人 时的状态 -->
					<div class="task-checktabel-right-one" v-show="selectedWorkItems.length == 1 && !isShowEmpty && !isCheckAll">
						<p class="f-12 h-57">{{ currentWorkItem.work_memo }}</p>
						<!-- 周期明细 -->
						<CheckManHour :details="currentWorkItem.details"></CheckManHour>
						<div class="mb-20"></div>
						<!-- 交付文件清单 -->
						<div class="task-file" v-if="currentWorkItem.work_files.length > 0">
							<div class="task-file-header">
								<strong class="f-18">交付文件清单</strong>
								<div class="mb-20"></div>
								<el-table
									:data="currentWorkItem.work_files"
									style="width: 100%"
									center
									class="checkmantab"
									border
									key="two"
									:header-cell-style="{ 'text-align': 'center', height: '32px', color: '#000', 'font-size': '12px', background: '#f4f4f4' }"
									:cell-style="{ height: '32px', color: '#474747', 'font-size': '12px' }"
								>
									>
									<el-table-column label="序号" key="twoC1" type="index"></el-table-column>
									<el-table-column prop="fileType" label="文件">
										<template slot-scope="{ row }">
                      <i :class="'b iconfont '+row.icon"></i>
											{{ row.name }}
										</template>
									</el-table-column>
									<el-table-column prop="workDescription" label="操作" width="120" style="text-align:center;">
										<template slot-scope="{ row }">
											<!--<el-button v-show="row.preview" type="text" @click="dialogVisible = true">预览</el-button>-->
											<el-button @click="jumpToDownload(row)" type="text" size="mini">下载</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
						<!-- 验收 -->
						<div class="checkMoney-tabel">
							<div class="checkMoney-tabel-header">
								<strong class="f-18">验收</strong>
								<div class="mb-20"></div>
							</div>
							<div class="mb-20"></div>
							<div class="checkMoney-tabel-input">
								<span class="mr-40 f-16 h-57 ">合同金额：¥ {{ currentWorkItem.plan_salary_amount_yuan }}</span>
								<p>
									<span class="f-16 h-57">实付金额：</span>
									<el-input
										v-if="showMoney"
										type="number"
										:min="0"
										:max="currentWorkItem.plan_salary_amount_yuan"
										v-model.number.trim="realAmount"
										placeholder="实付金额"
										style="width:120px"
									></el-input>
									<span v-else class="f-16 h-57">¥ {{ realAmount }}</span>
									<span v-show="canCheck && !showMoney" class="f-12 b" @click="afreshCheck" style="cursor:pointer">修改</span>
									<span v-show="showMoney" class="f-12 b" @click="unAfreshCheck" style="cursor:pointer">不修改</span>
								</p>
							</div>

							<div class="checkMoney-tabel-remark" v-loading="checkLoading">
								<strong class="f-1 h-57">验收备注：</strong>
								<el-button v-show="canCheck && !showMoney && currentWorkItem.status != 4" type="text" @click="reCheck">
									<i class="iconfont icon icon-revise b f-12">重新验收</i>
								</el-button>
								<div class="mb-10"></div>
								<el-input
									type="textarea"
									maxlength="300"
									show-word-limit
									placeholder="请填写验收备注"
									v-if="canCheck && showEdit"
									v-model="currentWorkItem.last_check_memo"
									class="input-60"
								></el-input>
								<el-card v-else shadow="hover" class="f-12 h-57" style="background-color: rgb(244, 244, 244);">
									<p v-show="currentWorkItem.check_status == 'success'"><i class="iconfont icon icon-success g f-16">验收通过</i></p>
									<p v-show="currentWorkItem.check_status == 'failed' "><i class="iconfont icon icon-fail f-16" style="color: red;">验收未通过</i></p>
									<div class="mb-10"></div>
									<p>{{ currentWorkItem.last_check_memo }}</p>
								</el-card>
							</div>
							<div class="mb-20"></div>
							<div class="checkMoney-tabel-button">
								<div class="checkMoney-tabel-button-border flex-sb">
									<el-button v-show="hasCheck && showEdit" type="text" style="position:relative;left:-20px" @click="unRecheck">取消操作</el-button>
									<el-button
										v-show="showEdit || currentWorkItem.status == 4"
										class="primary"
										@click="checkSingleWorkItem('y')"
										v-if="showMoney == true || showTextarea == true"
									>
										通过验收
									</el-button>
									<el-button
										v-show="showEdit || currentWorkItem.status == 4"
										class="refuse"
										@click="checkSingleWorkItem('n')"
										v-if="showMoney == true || showTextarea == true"
									>
										拒绝通过
									</el-button>
								</div>
							</div>
							<!-- 验收日志 -->
							<div class="checkMoney-tabel-blog">
								<div class="checkMoney-tabel-blog-header bortom">
									<strong class="f-18">操作记录</strong>
									<div class="mb-20"></div>
								</div>

								<div class="mb-20"></div>
								<div class="taskCheck-timeLine">
									<el-timeline>
										<el-timeline-item v-for="(log, index) in currentWorkItem.logs" :key="index" :timestamp="log.content">
											验收日志
											<span>{{ log.log_time }}</span>
										</el-timeline-item>
									</el-timeline>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InfoHeader from '@/views/layout/modules/hirer/infoHeader';
import CheckManHour from '@/views/layout/modules/hirer/checkManHour';
import { listWorkItems, loadWorkItem, checkWorkItem, loadWorkItemLogs, checkWorkItemBatch } from '@/api/taskuc.js';

export default {
	components: {
		InfoHeader,
		CheckManHour
	},
	name: 'taskCheck',
	data() {
		return {
			sn: '',
			periodId: 0,
			workItemList: [],
			workItemPage: {},
			workItemListPage: 1, //

			selectedWorkItems: [], //已经选中的人
			selectedFreelancerNames: [], //已选中人的名字
			currentWorkItem: {},
			currentWorkItemId: 0,

			maxBoxLoading: true,
			rightBoxLoaing: false,
			showMoney: false, //显示实付报酬
			showTextarea: true, //显示备注信息
			dialogVisible: false, //查看弹框
			realAmount: 0,
			showEdit: false,
			checkLoading: false,
			isCheckAll: false,

			batchCheckMemo: ''
		};
	},
	created() {
		this.currentWorkItemId = this.$route.query.work_item_id || 0;
		this.sn = this.$route.query.sn;
		this.periodId = this.$route.query.period_id;
		this.loadWorkItemList();
	},
	computed: {
		isShowEmpty: function() {
			return this.currentWorkItem.status < 4;
		},
		hasCheck: function() {
			return this.currentWorkItem.status == 5 || this.currentWorkItem.status == 6;
		},
		canCheck: function() {	//是否可以验收
			return this.currentWorkItem.allow_check;
		}
	},
	methods: {
		//加载工作清单列表
		async loadWorkItemList() {
			let res = await listWorkItems(
				{
					sn: this.sn,
					period_id: this.periodId,
					page: this.workItemListPage
				},
				this.$root.current_token
			);
			if (res.statusCode == 200) {
				this.workItemList = res.data.list;
				this.workItemPage = res.data.page;
				this.workItemListPage = this.workItemPage.page + 1;

				//获取当前的
				let _this = this;
				this.workItemList.map(function(item, key) {
					if (_this.currentWorkItemId == 0 && key == 0) {
						item.selected = 1;
						_this.loadOneWorkItem(item.id);
					} else if (_this.currentWorkItemId == item.id) {
						item.selected = 1;
						_this.loadOneWorkItem(item.id);
					} else {
						item.selected = 0;
					}
				});
			}
			this.maxBoxLoading = false;
		},

		//加载单个工作情况
		async loadOneWorkItem(workItemId) {
			this.rightBoxLoaing = true;
			let res = await loadWorkItem(workItemId, this.$root.current_token);
			this.rightBoxLoaing = false;
			if (res.statusCode == 200) {
				this.currentWorkItem = res.data;
				this.showEdit = this.currentWorkItem.status == 4 ? true : false;
				let c = this.selectedWorkItems.filter(function(item) {
					return item.id == workItemId;
				});
				if (c.length <= 0) {
					this.selectedWorkItems = [];
					this.selectedWorkItems.push(this.currentWorkItem);
				}
			}

			this.realAmount = this.currentWorkItem.real_salary_amount == 0 ? this.currentWorkItem.plan_salary_amount_yuan : this.currentWorkItem.real_salary_amount_yuan;

			console.log('realAmount');
			console.log(this.realAmount);
		},

		//验收单个工作
		checkSingleWorkItem(result) {
			if (result == 'n') {
				if (this.currentWorkItem.last_check_memo && this.currentWorkItem.last_check_memo == '') {
					this.$message.error('请输入验收备注');
					return false;
				}
			}

			if (this.realAmount > this.currentWorkItem.plan_salary_amount_yuan) {
				this.$message.error('验收金额不能超过合同金额');
				return false;
			}

			this.checkLoading = true;
			checkWorkItem(
				{
					work_item_id: this.currentWorkItem.id,
					result: result,
					check_memo: this.currentWorkItem.last_check_memo,
					amount: this.realAmount
				},
				this.$root.current_token
			).then(res => {
				if (res.statusCode == 200) {
					this.currentWorkItem.status = res.data.status;
					this.realAmount = res.data.real_salary_amount_yuan;
					this.currentWorkItem.real_salary_amount_yuan = res.data.real_salary_amount_yuan;
					this.unRecheck();
					this.listLogs();
					this.workItemListPage = 1;
					this.loadWorkItemList();
				} else {
					this.$notify.error({
						title: '错误',
						message: res.message
					});
				}
				let _this = this;
				setTimeout(function() {
					_this.checkLoading = false;
				}, 500);
			});
		},

		batchCheck( result ) {
			this.$confirm('批量操作会覆盖之前审核结果，您确定继续操作吗？')
				.then(_ => {
					if (!this.isCheckAll) {
						if (this.selectedWorkItems.length <= 0) {
							this.$message.error('请至少选中1项');
							return false;
						}
					}
					let workItemIds = []
					this.selectedWorkItems.forEach(function(item){
						workItemIds.push(item.id)
					});
					checkWorkItemBatch({
						period_id:this.periodId,
						check_all:this.isCheckAll ? 1: 0,
						result:result,
						work_item_ids:workItemIds,
					}).then(res=>{
						if( res.statusCode == 200 ){
							this.cancelAllCheck();
							this.workItemListPage = 1;
							this.loadWorkItemList();
						}else{
							this.$notify.error({
								title: '错误',
								message: res.message
							});
						}
					}).catch(res=>{})

				})
				.catch(_ => {});
		},
		allCheck() {
			this.isCheckAll = true;
		},
		cancelAllCheck() {
			this.isCheckAll = false;
		},
		listLogs() {
			loadWorkItemLogs(this.currentWorkItem.id, this.$root.current_token).then(res => {
				if (res.statusCode == 200) {
					this.currentWorkItem.logs = res.data;
				}
			});
		},

		clickData(row) {
			if (!this.isCheckAll) {
				this.loadOneWorkItem(row.id);
			}
		},

		selectableFunc(row, index) {
			if (this.isCheckAll) {
				return false;
			}

			if (row.status > 3 && row.status < 7) {
				return true;
			}
			return false;
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			//console.log( 'handleSelectionChange' );
			//console.log(val);
			this.selectedWorkItems = val;
			//this.multipleSelection = val;
		},
		rowstyle({ row, rowIndex }) {
			console.log(row, rowIndex);
			let stylejson = {};
			if (row.id == this.currentWorkItem.id) {
				stylejson.background = '#e0838f'; // 背景颜色
				// 也可以修改文字颜色
				stylejson.color = 'green';
				return stylejson;
			} else {
				return '';
			}
		},
		goTaskInfo() {
			this.$router.push({ path: '/home/hirer/task/info', query: { sn: this.sn, period_id: this.periodId } });
		},
		jumpToDownload( row ){
			window.open( row.url, '_blank' );
		},
		/* 通过验收 */
		passCheck() {
			this.showMoney = false;
			this.showTextarea = false;
		},
		reCheck() {
			this.showMoney = true;
			this.showEdit = true;
		},
		unRecheck() {
			this.showMoney = false;
			this.showEdit = false;
		},
		/* 重新验收 */
		afreshCheck() {
			this.showMoney = true;
			this.showEdit = true;
		},
		unAfreshCheck() {
			this.showMoney = false;
			this.showEdit = false;
		},
		/* 关闭弹窗 */
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then(_ => {
					done();
				})
				.catch(_ => {});
		},
		/* 取消操作 */
		cancel() {}
	}
};
</script>

<style lang="less" scoped>
.task-check {
	background-color: #fff;
	//头部部分
	.person-header {
		span {
			position: relative;
			top: 40px;
			right: 40px;
			cursor: pointer;
		}
	}
	//底部根据不同状态显示的tabel
	.task-checktabel {
		width: 860px;
		margin: 0 auto;
		.task-checktabel-left {
			margin-right: 40px;
			width: 200px;
			max-height: 200px;
			height: 0;
			/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
				background-color: #00a2e6 !important;
				color: #fff !important;
			}
			.task-checktabel-left-button {
				width: 200px;
				margin-bottom: 20px;
				height: 14px;
			}
		}
		.task-checktabel-right {
			width: 100%;
			.task-checktabel-right-no {
				width: 200px;
				margin: 60px auto 120px;
				text-align: center;
			}
			.task-checktabel-right-one {
				width: 100%;
				margin-top: 15px;
				margin-right: -40px;
				p {
					line-height: 22px;
				}
			}
			//验收
			.checkMoney-tabel {
				margin-top: 40px;
				.checkMoney-tabel-header {
					border-bottom: 1px solid #b5b5b5;
				}
				.checkMoney-tabel-input {
					height: 32px;
					display: flex;
					align-items: center;
					b {
						position: relative;
						left: -20px;
					}
				}
				.checkMoney-tabel-remark {
					margin-top: 10px;
				}
				.checkMoney-tabel-afresh {
					text-align: center;
					/deep/span {
						color: #00a2e6;
					}
				}
				.checkMoney-tabel-button {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					.checkMoney-tabel-button-border {
						width: 260px;
					}
				}
			}
			//验收日志
			.checkMoney-tabel-blog {
				.taskCheck-timeLine {
					padding-bottom: 30px;
					.taskCheckLi {
						line-height: 20px;
					}
					p {
						height: 15px;
						border: 1px solid red;
						position: relative;
						top: -6px;
						left: -20px;
						text-align: left;
						display: block;
					}
					/deep/.el-timeline-item__content {
						font-size: 12px;
						color: #575757;
						position: relative;
						top: 3px;
					}

					/deep/.el-timeline-item__timestamp {
						font-size: 12px;
						color: #575757;
						position: relative;
						right: -80px;
						top: -19px;
						width: 380px;
						line-height: 16px;
					}
					span {
						position: relative;
						left: -215px;
					}
				}
			}
		}
	}
}
/deep/.el-table__header .el-table-column--selection .cell .el-checkbox:after {
	color: #fff;
	display: inline-block;
	content: '';
	font-size: 12px;
	margin-left: 5px;
}
/* 去除padding */
/deep/.el-button {
	padding: 0;
}
/deep/.el-table .el-table__cell {
	padding: 0;
}
/deep/input[placeholder] {
	font-size: 12px;
	color: #575757;
}
.primary {
	width: 120px;
	height: 24px;
	background-color: #00a2e6;
	border-radius: 0;
	color: #fff;
	font-size: 12px;
}
.refuse {
	width: 120px;
	height: 24px;
	background-color: #fff;
	border-radius: 0;
	border-color: #00a2e6;
	color: #00a2e6;
	font-size: 12px;
}

.el-popover__reference-wrapper span {
	font-size: 12px;
}

/deep/.el-timeline-item {
	padding-bottom: 0;
}
/deep/.taskCheck-timeLine {
	margin-left: 125px;
}
/deep/.el-button--text {
	color: #575757;
}
/deep/.el-button--text:hover {
	color: #00a2e6;
}
/deep/.el-button--text.selected {
	color: #00a2e6;
}
/* 左侧工作成果验收 加载更多 */
.task-checktabel-left-tabel {
	/deep/.el-table .cell {
		text-overflow: clip;
		overflow: none;
	}
}
/deep/.task-checktabel-left-tabel-add {
	background-color: #f4f4f4;
	width: 200px;
	line-height: 32px;
	margin-top: 2px;
	text-align: center;
	color: #00a2e6 !important;
}
.input-60 {
	/deep/.el-textarea__inner {
		height: 80px;
	}
}
</style>
