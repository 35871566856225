<template>
  <div class="checkMan-hour" v-show="details.length>0">
    <div class="checkMan-hour-header flex-sb">
      <strong class="f-18">工时明细</strong>
      <span class="f-14 h-57">本周期工作总时长：{{totalHours}}小时</span>
    </div>
    <div class="mb-20"></div>
     <el-table :data="details" style="width: 100%;"
     center class="checkmantab" border key="two" max-height="352px" min-width="620px"
     :header-cell-style="{ 'text-align': 'center',height: '32px', color: '#000','font-size':'12px',background:'#f4f4f4'}"
     :cell-style="{ 'text-align': 'center', height: '32px', color: '#575757','font-size':'12px'}" > >
      <el-table-column prop="date_day" width="100" label="日期" key="twoC1"></el-table-column>
      <el-table-column prop="week_day" width="100" label="星期" ></el-table-column>
      <el-table-column prop="effective_hourly" width="80" label="工作时长"></el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="工作描述">
		   <template slot-scope="{ row }">
			   <el-tooltip class="item" effect="dark" :content="row.work_memo" placement="top-start">
				<span>{{row.work_memo}}</span>
			   </el-tooltip>
		   </template>
	  </el-table-column>
      <el-table-column  label="凭证" width="250">
		  <template slot-scope="{ row }">
			<span v-for="(file,key) in row.work_files" style="padding:0 5px;">
				<el-tooltip class="item" effect="dark" :content="file.name" placement="top-start">
					<el-button type="text"><i :class="'b iconfont '+file.icon"></i>下载</el-button>
				</el-tooltip>
			</span>
		  </template>
	  </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name:"checkManHour",
  data(){
    return{
    }
  },
  props:{
	  details:{
		  type:Array
	  }
  },
  computed:{
	  totalHours(){
		 return this.details.reduce((sum,item) => {
			  return sum += item.effective_hourly;
		  }, 0 )
	  }
  }
}
</script>

<style lang="less" scoped>
.checkMan-hour{
  margin-top: 40px;
  .checkMan-hour-{
    height: 18px;
    align-items: center;
  }
}
/deep/.el-table
/deep/.el-table__cell{
  padding: 0;
}
</style>
